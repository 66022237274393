/* eslint-disable */

import {
    FaApple,
    FaCode,
    FaCoffee, FaDatabase, FaDiscord,
    FaDonate,
    FaFacebookF, FaGit, FaGitlab, FaGitter,
    FaHandshake,
    FaInstagram,
    FaLinkedinIn, FaMobileAlt, FaProjectDiagram,
    FaYoutube
} from "react-icons/fa";
import {
    FiBarChart,
    FiBarChart2,
    FiBriefcase,
    FiCheckCircle,
    FiLock,
    FiTarget,
    FiUserCheck
} from "react-icons/fi";
import {
    DiReact,
    DiDotnet,
    DiJava,
    DiPython,
    DiPhp,
    DiJavascript,
    DiLaravel,
    DiDjango,
    DiCode,
    DiLinux,
    DiDigitalOcean, DiMysql, DiMongodb, DiJira
} from "react-icons/di";
import {
    SiDocker,
    SiMicrosoftazure,
    SiKubernetes,
    SiPypi,
    SiWebstorm,
    SiPhp,
    SiJetbrains,
    SiPython,
    SiIntellijidea,
    SiVisualstudio,
    SiVisualstudiocode,
    SiXcode,
    SiAndroidstudio,
    SiPostman,
    SiMicrosoftsqlserver,
    SiRancher,
    SiIos,
    SiMicrosoft,
    SiAmazonaws,
    SiPostgresql,
    SiElasticsearch, SiGooglehangoutsmeet, SiMicrosoftteams, SiMicrosoftsharepoint, SiClockify
} from "react-icons/si";
import {BiGlobe, BiTestTube, BiWindows} from "react-icons/bi";

import React from "react";

import logo from "../assets/images/logo.png";
import flat1 from '../assets/images/flag.jpg'; // 33x33
import flat2 from '../assets/images/flag2.jpg'; // 33x33
import flat3 from '../assets/images/flag3.jpg'; // 33x33
import herosliderbg from '../assets/images/shop-slider1.jpg'; // 1920x1024
import conceptslider from '../assets/images/digital-blue-hud-interface-laptop-concept.jpg'; // 1920x1024
import strategyconcept from '../assets/images/analytics-plan-strategy-insight-concept.jpg'; // 1920x1024
import condingman from '../assets/images/coding-man.jpg'; // 1920x1024

import {MdAccountBalance} from "react-icons/md";
import {
    AiOutlineCheckCircle,
    AiOutlineDollar,
    AiOutlineFileSearch,
    AiOutlineNotification, AiOutlineRocket,
    AiOutlineUsergroupAdd
} from "react-icons/ai";
import {IoIosBuild, IoIosHammer, IoIosNotificationsOutline, IoLogoPython} from "react-icons/io";
import {TiLightbulb} from "react-icons/ti"; // 750x470
import edificioVarig from '../assets/images/EdificioVarig.jpg';
import reuniao1 from '../assets/images/Reuniao-01.jpeg';
import reuniao2 from '../assets/images/Reuniao-02.jpeg';
import img1 from '../assets/images/about-img.jpg'; // 458x315
import img2 from '../assets/images/about-img4.jpg'; // 555x354
import img3 from "../assets/images/testi-img1.jpg"; // 100x100
import img4 from '../assets/images/team-1.jpg'; // 360x370
import img5 from "../assets/images/client1.png"; // 110x39
import img6 from "../assets/images/client2.png"; // 160x32
import img7 from "../assets/images/client3.png"; // 156x33
import img8 from "../assets/images/client4.png"; // 137x36
import img9 from "../assets/images/client5.png"; // 161x35
import img10 from "../assets/images/img1.jpg"; // 555x368
import img11 from '../assets/images/img11.jpg'; // 1140x594
import img12 from "../assets/images/team-8.jpg"; // 360x453
import img13 from "../assets/images/img3.jpg"; // 360x240
import img14 from "../assets/images/img15.jpg"; // 1140x608
import slider1 from "../assets/images/img12.jpg"; // 750x469
import img15 from "../assets/images/img22.jpg"; // 350x256
import errorimg from "../assets/images/404-img3.gif"; // 550x476
import chartimg from "../assets/images/chart-bar.png";
import category1 from "../assets/images/shopimg.jpg"; // 570x290
import CountUp from "react-countup";
import footerLogo from "../assets/images/logo2.png";
import {GiCutDiamond} from "react-icons/gi";

import DERRICOLogoBranco from '../assets/images/Derrico Consultoria Logotipo Branco.png';
import DERRICOWebConsulting from '../assets/images/DERRICOWebConsulting.webp';
import DERRICOWebDevelopment from '../assets/images/DERRICOWebDevelopment.webp';
import DERRICOMobileDevelopment from '../assets/images/DERRICOMobileDevelopment.webp';
import DERRICOExperience from '../assets/images/DERRICOExperience.webp';
import DERRICOTecnologyDomain from '../assets/images/DERRICOTecnologyDomain.webp';
import DERRICOBlog from '../assets/images/DERRICOBlog.webp'
import DERRICODigitalSolution from '../assets/images/DERRICODigitalSolution.webp';
import DERRICOBestResults from '../assets/images/DERRICOBestResults.webp';
import DERRICOProofExpertise from '../assets/images/DERRICOProofExpertise.webp';
import DERRICOOnlineAttending from '../assets/images/DERRICOOnlineAttending.webp';
import DERRICOInovation from '../assets/images/DERRICOInovation.webp';
import DERRICOCustomizedSolutions from '../assets/images/DERRICOCustomizedSolutions.webp';
import {CgBitbucket, FcIpad, GoX, GrGithub, GrOracle} from "react-icons/all";


const sectiondata = {
    HeaderData: {
        HeaderBar: {
            phoneNumber: '+55 61 3053-5484',
            email: 'contato@derrico.tec.br',
            location: 'Brasília DF, Brasil',
            socialLists: [
                {
                    icon: <FaLinkedinIn />,
                    url: 'https://www.linkedin.com/company/derrico/'
                },
                {
                    icon: <FaInstagram />,
                    url: 'https://www.instagram.com/derrico.consultoria'
                },
                {
                    icon: <FaFacebookF />,
                    url: 'https://facebook.com/derriconsultoria'
                },
                {
                    icon: <GoX />,
                    url: 'https://twitter.com/DERRICOnsulting'
                },
                {
                    icon: <FaDiscord />,
                    url: 'https://discord.gg/2ydrKMPW'
                },
                // {
                //     icon: <FaYoutube />,
                //     url: 'https://youtube.com'
                // }
            ],
            languages: [
                // {
                //     name: 'Português',
                //     url: '#',
                //     language: 'pt'
                // },
                // {
                //     name: 'English',
                //     url: '#',
                //     language: 'en'
                // }
            ],
            // btntext: "Trabalhe Conosco",
            // btnicon: <FiChevronRight className="icon" />,
            // btnurl: '#'
        },
        HeaderMenuBar: {
            logourl: logo,
            menus: [
                {
                    title: 'Home',
                    path: '/',
                },
                {
                    title: 'Sobre Nós',
                    path: '/about'
                },
                {
                    title: 'Inovação Colaborativa',
                    path: '#',
                    dropdown: [
                        {
                            title: 'Soluções Digitais',
                            path: '/collaborative-inovation',
                        },
                        {
                            title: 'Tecnologias Utilizadas',
                            path: '/services',
                        },
                        // {
                        //     title: 'Produtos',
                        //     path: '/service-details',
                        // }
                    ]
                },
                // {
                //     title: 'Páginas',
                //     path: '#',
                //     dropdown: [
                //         {
                //             title: 'Sobre',
                //             path: '/about',
                //         },
                //         {
                //             title: 'Equipe',
                //             path: '/team-grid',
                //             dep_dropdown: [
                //                 {
                //                     title: 'Team Grid',
                //                     path: '/team-grid',
                //                 },
                //                 {
                //                     title: 'Team Details',
                //                     path: '/team-details',
                //                 }
                //             ]
                //         },
                //         {
                //             title: 'Testimonial',
                //             path: '/testimonial',
                //         },
                //         {
                //             title: 'FAQ',
                //             path: '/faq',
                //         },
                //         {
                //             title: 'Our Pricing',
                //             path: '/our-pricing',
                //         },
                //         {
                //             title: '404',
                //             path: '/error',
                //         },
                //         {
                //             title: 'User',
                //             path: '/sign-up',
                //             dep_dropdown: [
                //                 {
                //                     title: 'Sign Up',
                //                     path: '/sign-up',
                //                 },
                //                 {
                //                     title: 'Login',
                //                     path: '/login',
                //                 },
                //                 {
                //                     title: 'Recover Password',
                //                     path: '/forgot-password',
                //                 }
                //             ]
                //         }
                //     ],
                // },
                // {
                //     title: 'Portfolio',
                //     path: '/portfolio-grid',
                //     dropdown: [
                //         {
                //             title: 'Portfolio Grid',
                //             path: '/portfolio-grid',
                //         },
                //         {
                //             title: 'Portfolio Details',
                //             path: '/portfolio-details',
                //         }
                //     ]
                // },
                // {
                //     title: 'Blog',
                //     path: '/blog-grid',
                    // dropdown: [
                    //     {
                    //         title: 'Blog Full Width',
                    //         path: '/blog-full-width',
                    //     },
                    //     {
                    //         title: 'Blog Grid',
                    //         path: '/blog-grid',
                    //     },
                    //     {
                    //         title: 'Blog Details',
                    //         path: '/blog-details',
                    //     }
                    // ]
                // },
                // {
                //     title: 'Shop',
                //     path: '/shop-home',
                //     dropdown: [
                //         {
                //             title: 'Shop Home',
                //             path: '/shop-home',
                //         },
                //         {
                //             title: 'Shop Sidebar',
                //             path: '/shop',
                //         },
                //         {
                //             title: 'Product Details',
                //             path: '/product-details',
                //         },
                //         {
                //             title: 'Cart',
                //             path: '/cart',
                //         },
                //         {
                //             title: 'Checkout',
                //             path: '/checkout',
                //         }
                //     ]
                // },
                {
                    title: 'Contato',
                    path: '/contact',
                }
            ]
        }
    },
    Sliders: {
        heroSlider1: [
            {
                title: 'Potencialize Sua Presença Online',
                description: 'Desbloqueie o potencial do seu negócio com nossos serviços especializados de consultoria web. Desde o desenvolvimento de estratégias até a otimização de desempenho, guiamos você a cada passo para garantir que sua presença online seja poderosa e lucrativa.',
                button1: 'Impulsione Seu Negócio',
                button1url: '/contact',
                bg: DERRICOWebConsulting,
                col: '10'
            },
            {
                title: 'Soluções Personalizadas, Resultados Transformadores',
                description: 'Transforme suas ideias em realidade com nossos serviços de desenvolvimento de software sob medida. Nos especializamos na criação de aplicações escaláveis e eficientes, feitas sob medida para atender às necessidades únicas do seu negócio, impulsionando inovação e crescimento.',
                button1: 'Vamos Inovar Juntos',
                button1url: '/contact',
                bg: DERRICOWebDevelopment,
                col: '10'
            },
            {
                title: 'Mobilize Sua Estratégia Digital',
                description: 'Mantenha-se à frente no mundo mobile com nossos serviços abrangentes de desenvolvimento mobile. Seja iOS ou Android, projetamos e desenvolvemos aplicações suaves e fáceis de usar que mantêm seus usuários engajados e conectados.',
                button1: 'Inicie Seu Projeto',
                button1url: '/contact',
                bg: DERRICOMobileDevelopment,
                col: '10'
            },
            {
                title: 'Experiência e Inovação ao Seu Alcance',
                description: 'Com anos de experiência em diversos segmentos, oferecemos uma infraestrutura robusta e conhecimento aprofundado em tecnologia digital para superar suas expectativas. Estamos equipados para fornecer soluções inovadoras e personalizadas que impulsionam o sucesso e a satisfação do cliente.',
                button1: 'Faça Parte',
                button1url: '/clients',
                bg: DERRICOExperience,
                col: '12'
            },
            {
                title: 'Domínio Tecnológico para Cada Desafio',
                description: 'Na DERRICO Consultoria, transformamos complexidade em soluções sob medida. Com vasto conhecimento em tecnologias de ponta, estamos prontos para atender qualquer necessidade e impulsionar seu sucesso.',
                button1: 'Conheça Nosso Domínio',
                button1url: '/services',
                bg: DERRICOTecnologyDomain,
                col: '6'
            },
            // {
            //     title: 'Venha Descobrir as Últimas Tendências com o Blog da DERRICO Consultoria!',
            //     description: 'Mergulhe em insights profundos e conteúdo relevante que vai além das expectativas. No Blog da DERRICO Consultoria, exploramos as tecnologias mais recentes, trazemos dicas de especialistas e compartilhamos histórias que inspiram inovação. Clique aqui e fique por dentro do que há de mais novo no mundo da tecnologia!',
            //     button1: 'Acessar Blog',
            //     button1url: '/blog-grid',
            //     bg: DERRICOBlog,
            //     col: 12
            // }
        ],
        testimonial: [
            {
                name: "Tanbir_Ahmed",
                city: "United States",
                img: img1,
                desc: "Enjoy the diversity of ThePin. Check this numerous demos, made for different purposes. Easy to import & highly customizable. All of the presented elements, layouts & styles can be theme.",
            },
            {
                name: "Mark_Doe",
                city: "United States",
                img: img1,
                desc: "Enjoy the diversity of ThePin. Check this numerous demos, made for different purposes. Easy to import & highly customizable. All of the presented elements, layouts & styles can be theme."
            },
            {
                name: "Rizon_Pet",
                city: "United States",
                img: img1,
                desc: "Enjoy the diversity of ThePin. Check this numerous demos, made for different purposes. Easy to import & highly customizable. All of the presented elements, layouts & styles can be theme.",
            }
        ],
        clientlogos: [
            {
                img: img5
            },
            {
                img: img6
            },
            {
                img: img7
            },
            {
                img: img8
            },
            {
                img: img9
            }
        ],
        heroSlider2: [
            {
                title: 'Minzel Gives You Power to Grow Your Business.',
                description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem veritatis et quasi architecto beatae vitae dicta.',
                button1: 'Get Started',
                button2: 'Learn More',
                button1url: '/',
                button2url: '/',
                bg: herosliderbg,
                col: '8'
            },
            {
                title: 'Your Business Success is Our Success.',
                description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem veritatis et quasi architecto beatae vitae dicta.',
                button1: 'Let\'s Start Now',
                button2: 'Learn More',
                button1url: '/',
                button2url: '/',
                bg: herosliderbg,
                col: '8 offset-2 text-center'
            },
            {
                title: 'Work With World Class Finance Experts.',
                description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem veritatis et quasi architecto beatae vitae dicta.',
                button1: 'Let\'s Start Now',
                button2: 'Learn More',
                button1url: '/',
                button2url: '/',
                bg: herosliderbg,
                col: '8'
            }
        ],
        shopheroslider: [
            {
                title: 'Hand Picked Items Sold Exclusively Online.',
                description: "Deserunt dolore voluptatem assumenda quae possimus sunt dignissimos tempora officia. Lorem ipsum dolor sit amet",
                button1: 'Shop Now',
                button2: 'All Products',
                button1url: '/shop',
                button2url: '/shop',
                bg: herosliderbg,
                col: '8'
            },
            {
                title: 'Find That Perfect Gift and Surprise Someone.',
                description: "Deserunt dolore voluptatem assumenda quae possimus sunt dignissimos tempora officia. Lorem ipsum dolor sit amet",
                button1: 'Shop Now',
                button2: 'All Products',
                button1url: '/shop',
                button2url: '/shop',
                bg: herosliderbg,
                col: '8 offset-2 text-center'
            },
            {
                title: 'Beautifully Design Pieces by  Talented Designers..',
                description: "Deserunt dolore voluptatem assumenda quae possimus sunt dignissimos tempora officia. Lorem ipsum dolor sit amet",
                button1: 'Shop Now',
                button2: 'All Products',
                button1url: '/shop',
                button2url: '/shop',
                bg: herosliderbg,
                col: '8'
            }
        ]
    },
    Services: {
        Service1: {
            secTitle: 'Linguagens de Programação',
            secSubtitle: 'Tecnologias',
            // secButton: 'Exibir Todos Serviços',
            // secButtonUrl: '/',
            serviceLists: [
                {
                    icon: <DiJavascript className="service-items-icon" />,
                    title: 'Javascript',
                    titleurl: '/service-details',
                    description: 'O Javascript é uma linguagem de programação. Seu ecossistema atual tem muitas bibliotecas e frameworks, práticas de programação estabelecidas e aumento do uso fora dos navegadores da Web.',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <DiDotnet className="service-items-icon" />,
                    title: 'dotNET',
                    titleurl: '/service-details',
                    description: '.NET é uma plataforma de desenvolvimento gratuita e de software livre para a criação de muitos tipos de aplicativos, como Aplicativos Web, APIs Web e Microsserviços, entre outros.',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <DiJava className="service-items-icon" />,
                    title: 'Java',
                    titleurl: '/service-details',
                    description: 'Java é uma linguagem de programação orientada a objetos, conhecida por "escreva uma vez, execute em qualquer lugar". É usada para desenvolver aplicativos mobile, empresarial e sistemas embarcados.',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <DiPython className="service-items-icon" />,
                    title: 'Python',
                    titleurl: '/service-details',
                    description: 'Python é uma linguagem de programação de alto nível, famosa por sua clareza e simplicidade. É amplamente utilizada em ciência de dados, desenvolvimento web e automação.',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <DiPhp className="service-items-icon" />,
                    title: 'Php',
                    titleurl: '/service-details',
                    description: 'PHP é uma linguagem de script do lado do servidor, amplamente utilizada para o desenvolvimento web. É ideal para criar sites dinâmicos e interativos, suportando vários bancos de dados e integrando-se facilmente com HTML.',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
            ]
        },
        Service4: {
            secTitle: 'Frameworks',
            secSubtitle: 'Tecnologias',
            // secButton: 'Exibir Todos Serviços',
            // secButtonUrl: '/',
            serviceLists: [
                {
                    icon: <DiLaravel className="service-items-icon" />,
                    title: 'Laravel',
                    titleurl: '/service-details',
                    description: 'Laravel é um framework PHP para desenvolvimento web, focado em simplicidade e elegância. Oferece ferramentas robustas para aplicações modernas, com ênfase em código limpo e funcionalidades ricas.',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <FaCoffee className="service-items-icon" />,
                    title: 'Spring Boot',
                    titleurl: '/service-details',
                    description: 'Spring Boot é um framework Java que facilita a criação de aplicações web e microserviços de maneira rápida e eficiente, oferecendo configuração automática e uma vasta gama de funcionalidades prontas para uso.',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <FaMobileAlt className="service-items-icon" />,
                    title: 'Cordova',
                    titleurl: '/service-details',
                    description: 'Cordova é um framework de desenvolvimento mobile que permite criar aplicativos usando HTML, CSS e JavaScript, convertidos em aplicativos nativos para plataformas como iOS e Android, facilitando o desenvolvimento com um único código.',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <DiReact className="service-items-icon" />,
                    title: 'React Native',
                    titleurl: '/service-details',
                    description: 'React Native é um framework de desenvolvimento mobile que usa JavaScript para criar aplicativos nativos para iOS e Android. Permite um desenvolvimento eficiente com um código-base compartilhado entre plataformas.',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <FaCode className="service-items-icon" />,
                    title: 'ASP.NET Core',
                    titleurl: '/service-details',
                    description: 'Um framework para desenvolvimento de aplicações web e serviços web. É leve, de alto desempenho e pode ser usado para construir aplicações modernas, tanto no lado do servidor quanto em ambientes de nuvem.',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <FaDatabase className="service-items-icon" />,
                    title: 'Entity',
                    titleurl: '/service-details',
                    description: 'Um framework de mapeamento objeto-relacional para .NET, usado para acessar e gerenciar dados de bancos de dados usando objetos .NET. Facilita a interação com o banco de dados, sem necessitar escrever consultas SQL manualmente.',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <DiDjango className="service-items-icon" />,
                    title: 'Django',
                    titleurl: '/service-details',
                    description: 'Django é um framework Python \'completo\' para desenvolvimento rápido de aplicações web seguras e escaláveis, ideal para projetos complexos.',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <DiCode className="service-items-icon" />,
                    title: 'Flask',
                    titleurl: '/service-details',
                    description: 'Flask é um framework Python leve e flexível, perfeito para desenvolver aplicações web simples ou para projetos que necessitam de personalização.',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiPypi className="service-items-icon" />,
                    title: 'Fast API',
                    titleurl: '/service-details',
                    description: 'FastAPI é um framework Python moderno e de alto desempenho, projetado para criar APIs web eficientes e fáceis de documentar, usando padrões modernos.',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
            ]
        },
        Service5: {
            secTitle: 'IDEs e Ferramentas para Desenvolvimento',
            secSubtitle: 'Tecnologias',
            // secButton: 'Exibir Todos Serviços',
            // secButtonUrl: '/',
            serviceLists: [
                {
                    icon: <SiWebstorm className="service-items-icon" />,
                    title: 'WebStorm',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiPhp className="service-items-icon" />,
                    title: 'PHPStorm',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiPython className="service-items-icon" />,
                    title: 'PyCharm',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiIntellijidea className="service-items-icon" />,
                    title: 'IntelliJ',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiJetbrains className="service-items-icon" />,
                    title: 'Rider',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <FaDatabase className="service-items-icon" />,
                    title: 'DataGrip',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiVisualstudiocode className="service-items-icon" />,
                    title: 'VSCode',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiVisualstudio className="service-items-icon" />,
                    title: 'Visual Studio',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiXcode className="service-items-icon" />,
                    title: 'XCode',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiAndroidstudio className="service-items-icon" />,
                    title: 'Android Studio',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiPostman className="service-items-icon" />,
                    title: 'Postman',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiMicrosoftsqlserver className="service-items-icon" />,
                    title: 'SQL Server Management Studio',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <FaProjectDiagram className="service-items-icon" />,
                    title: 'PowerDesigner',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiDocker className="service-items-icon" />,
                    title: 'Docker',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiRancher className="service-items-icon" />,
                    title: 'Rancher',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiKubernetes className="service-items-icon" />,
                    title: 'Kubernetes',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
            ]
        },
        Service6: {
            secTitle: 'Banco de Dados Relacionais e NoSQL',
            secSubtitle: 'Tecnologias',
            // secButton: 'Exibir Todos Serviços',
            // secButtonUrl: '/',
            serviceLists: [
                {
                    icon: <DiMysql className="service-items-icon" />,
                    title: 'MySQL',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <GrOracle className="service-items-icon" />,
                    title: 'Oracle',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiPostgresql className="service-items-icon" />,
                    title: 'PostgreSQL',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiMicrosoftsqlserver className="service-items-icon" />,
                    title: 'SQL Server',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiElasticsearch className="service-items-icon" />,
                    title: 'Elasticsearch',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <DiMongodb className="service-items-icon" />,
                    title: 'MongoDB',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },

            ]
        },
        Service7: {
            secTitle: 'Sistemas Operacionais e Nuvens',
            secSubtitle: 'Tecnologias',
            // secButton: 'Exibir Todos Serviços',
            // secButtonUrl: '/',
            serviceLists: [
                {
                    icon: <FaApple className="service-items-icon" />,
                    title: 'MacOS',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiIos className="service-items-icon" />,
                    title: 'iOS',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <FcIpad className="service-items-icon" />,
                    title: 'iPadOS',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiMicrosoft className="service-items-icon" />,
                    title: 'Windows',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <DiLinux className="service-items-icon" />,
                    title: 'Linux',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiMicrosoftazure className="service-items-icon" />,
                    title: 'Microsoft Azure',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiAmazonaws className="service-items-icon" />,
                    title: 'Amazon AWS',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <DiDigitalOcean className="service-items-icon" />,
                    title: 'Digital Ocean',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
            ]
        },
        Service8: {
            secTitle: 'Gerenciamento de Códigos e Projetos',
            secSubtitle: 'Tecnologias',
            // secButton: 'Exibir Todos Serviços',
            // secButtonUrl: '/',
            serviceLists: [
                {
                    icon: <FaGit className="service-items-icon" />,
                    title: 'Git',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <FaGitter className="service-items-icon" />,
                    title: 'GitFlow',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <FaGitlab className="service-items-icon" />,
                    title: 'GitLab',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <GrGithub className="service-items-icon" />,
                    title: 'GitHub',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <CgBitbucket className="service-items-icon" />,
                    title: 'BitBucket',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <DiJira className="service-items-icon" />,
                    title: 'Jira',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiMicrosoftteams className="service-items-icon" />,
                    title: 'Microsoft Teams',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiMicrosoftsharepoint className="service-items-icon" />,
                    title: 'Microsoft Sharepoint',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiGooglehangoutsmeet className="service-items-icon" />,
                    title: 'Google Meet',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiClockify className="service-items-icon" />,
                    title: 'Clockify',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
            ]
        },
        Service2: {
            sectitle: 'Nós Oferecemos Diversos Serviços Empresariais.',
            secsubtitle: 'Você Merece os Melhores Serviços',
            content: 'Transforme seu negócio com nossas soluções inovadoras em consultoria web e desenvolvimento de software para web e mobile. Oferecemos uma ampla gama de serviços empresariais personalizados para elevar sua presença online e otimizar seus processos operacionais. Conecte-se conosco e descubra como podemos ajudá-lo a alcançar novos patamares!',
            buttontext: 'Read More',
            buttonurl: '/service-details',
            lists: [
                {
                    icon: <AiOutlineDollar />,
                    title: "Consumer Products",
                    titleurl: '/service-details',
                    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing"
                },
                {
                    icon: <FiBriefcase />,
                    title: "Adult Marketing",
                    titleurl: '/service-details',
                    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing"
                },
                {
                    icon: <FaDonate />,
                    title: "Banking Advising",
                    titleurl: '/service-details',
                    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing"
                },
                {
                    icon: <FiBarChart2 />,
                    title: "Business Growth",
                    titleurl: '/service-details',
                    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing"
                }
            ]
        },
        Service3: [
            {
                icon: <FiTarget className="service-items-icon" />,
                title: 'Nossa Missão',
                // titleurl: '/service-details',
                description: 'Nossa missão é capacitar empresas fornecendo soluções de ponta para web e dispositivos mobile que impulsionam a inovação e a eficiência. Nos concentramos em criar produtos digitais robustos e escaláveis que permitem aos nossos clientes prosperar em uma paisagem tecnológica em rápida evolução.',
                // showbtn: true,
                // buttontext: 'Learn More',
                // buttonurl: '/service-details',
            },
            {
                icon: <IoIosNotificationsOutline className="service-items-icon" />,
                title: 'Nossa Visão',
                // titleurl: '/service-details',
                description: 'Nossa visão é ser o principal parceiro de serviços de desenvolvimento web e mobile, reconhecido por transformar a maneira como as empresas interagem com a tecnologia. Aspiramos estabelecer padrões da indústria ao impulsionar consistentemente os limites da inovação, ajudando nossos clientes a alcançar seu potencial máximo na era digital.',
                // showbtn: true,
                // buttontext: 'Learn More',
                // buttonurl: '/service-details',
            },
            {
                icon: <FaHandshake className="service-items-icon" />,
                title: 'Nossa Abordagem',
                // titleurl: '/service-details',
                description: 'Nossa abordagem combina expertise técnica com insights estratégicos para entregar soluções personalizadas que resolvem os problemas dos nossos clientes. Valorizamos um atendimento de qualidade e nos sentimos realizados quando vemos que nossos clientes alcançam seus objetivos. Através de um processo colaborativo e o uso de tecnologias avançadas, garantimos soluções que superam as expectativas.',
                // showbtn: true,
                // buttontext: 'Learn More',
                // buttonurl: '/service-details',
            }
        ],
        serviceVideo: {
            videoId: 't3tsMEhlvwM', // Enter YouTube Video's id here
            videobutton: 'Watch',
            img: img11
        },
        serviceDetails: {
            sliders: [
                slider1,
                slider1,
                slider1,
            ],
            title: 'International Business Opportunities.',
            content1: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered altera tion in some form, by injected humour, or randomised words which don’t look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn’t anything embarrassing hidden.',
            content2: 'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.',
            features: [
                {
                    title: 'Seed do eiusmod tempor incididunt ut',
                    icon: <FiCheckCircle className="icon" />
                },
                {
                    title: 'Exercitation ullamco laboris nis.',
                    icon: <FiCheckCircle className="icon" />
                },
                {
                    title: 'Quis nostrud exerc citation.',
                    icon: <FiCheckCircle className="icon" />
                },
                {
                    title: 'Andomised words which don\'t look',
                    icon: <FiCheckCircle className="icon" />
                },
                {
                    title: 'Commodo consequat duis autex.',
                    icon: <FiCheckCircle className="icon" />
                },
                {
                    title: 'Andomised words which don\'t look',
                    icon: <FiCheckCircle className="icon" />
                },
                {
                    title: 'All the Lorem ipsum generators',
                    icon: <FiCheckCircle className="icon" />
                },
            ],
            featuresimg: img13,
            plans: {
                img: img13,
                title: 'Planning Strategy',
                content: 'Adipisicing elit, sed do eiusmod tempor incididunt ul labore et dolore magna aliqua',
                lists: [
                    {
                        title: 'research beyond the business plan',
                        icon: <FiCheckCircle className="icon" />
                    },
                    {
                        title: 'marketing options and rates.',
                        icon: <FiCheckCircle className="icon" />
                    },
                    {
                        title: 'the ability to turnaround consulting.',
                        icon: <FiCheckCircle className="icon" />
                    },
                ]
            },
            project: {
                title: 'Project Results.',
                content1: 'There are many variations of passages of Lorem ipsum available, but the majority have suffered altera tion in some form, by injected,',
                content2: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore. eu fugiat nulla pariatur.',
                content3: 'Enim minim veniam quis nostrud. exercitation ullamco laboris nisi aliquip'
            },
            sidebar: {
                categories: {
                    title: 'Categories.',
                    lists: [
                        {
                            title: 'Finance Management',
                            path: '/service-details',
                        },
                        {
                            title: 'Banking Investigation',
                            path: '/service-details',
                        },
                        {
                            title: 'Business Insurance',
                            path: '/service-details',
                        },
                        {
                            title: 'Car Insurance',
                            path: '/service-details',
                        },
                        {
                            title: 'Market Research',
                            path: '/service-details',
                        },
                        {
                            title: 'Home Insurance',
                            path: '/service-details',
                        },
                        {
                            title: 'Life Insurance',
                            path: '/service-details',
                        },
                        {
                            title: 'Health Insurance',
                            path: '/service-details',
                        },
                    ]
                },
                contactinfos: {
                    title: 'Contact Us.',
                    address: '121 King Street, USA, New York',
                    phonenumber: '+1401574423',
                    email: 'example@support.com'
                },
                broucher: {
                    title: 'Brochures',
                    content: 'View our 2020 financial prospectus brochure for an easy to read guide on all of the services offer.',
                    button: 'Company Presentation',
                    buttonurl: '#', // Enter download file url here
                }
            }
        }
    },
    Aboutdata: {
        about1: {
            title: 'Bem-vindo à DERRICO Consultoria',
            subtitle: 'Conheça Sobre Nós',
            content: 'Localizada em Brasília/DF, nossa empresa é um centro de excelência em Consultoria em Tecnologia da Informação e Desenvolvimento de Softwares. Nos especializamos em criar soluções digitais personalizadas que se adaptam perfeitamente às necessidades únicas de cada cliente. Enquanto nosso foco principal é atender clientes de forma online, possuímos também uma estrutura física robusta para proporcionar atendimento presencial sempre que necessário. Nosso compromisso é transformar desafios tecnológicos em oportunidades de crescimento para sua empresa, garantindo inovação, qualidade e resultados excepcionais.',
            // readmorebtn: 'Read More',
            // readmorebtnurl: '/about',
            supports: [
                {
                    icon: <TiLightbulb />,
                    title: 'Soluções de TI Personalizadas'
                },
                {
                    icon: <FiTarget />,
                    title: 'Parceiros Estratégicos de Crescimento'
                },
                {
                    icon: <BiGlobe />,
                    title: 'Expertise Local, Padrões Globais'
                }
            ],
            images: [
                edificioVarig,
                reuniao2,
                // reuniao1,
            ],
            videobtn: 'Play',
            videoId: 't3tsMEhlvwM', // Enter YouTube video's id here
            skills: [
                // {
                //     title: 'Finance.',
                //     percent: '90'
                // },
                // {
                //     title: 'Business.',
                //     percent: '85'
                // },
                // {
                //     title: 'Investment.',
                //     percent: '80'
                // }
            ]
        },
        about2: {
            left: {
                title: 'We Allow You to Manage & Grow Your Business.',
                content1: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don\'t look even slightly believable.',
                content2: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium assumenda distinctio eligendi eum molestiae odit ullam?',
                lists: [
                    "Lorem ipsum dolor sit amet.",
                    "Lorem ipsum dolor sit amet.",
                    "Lorem ipsum dolor sit amet."
                ],
                buttontext: 'Get Started',
                buttonurl: '/'
            },
            right: {
                img1: img10,
                img2: img10,
                title: 'We Are Trusted by More Than 19,700 Clients',
                content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour',
                buttontext: 'Join Now',
                buttonurl: '/'
            }
        }
    },
    digitalsolutiondata: {
        img: DERRICODigitalSolution,
        title: 'Parceria na Criação: Conectando Inovação e Tecnologia.',
        content1: 'Nós acreditamos que o verdadeiro progresso é alcançado através da colaboração. Nossa empresa é especializada em consultoria web e desenvolvimento de soluções digitais personalizadas para plataformas web e mobile. Com uma equipe dedicada de especialistas em tecnologia, transformamos suas ideias em realidade, assegurando que cada projeto reflita suas necessidades únicas e objetivos comerciais.',
        content2: 'Nossa abordagem consultiva começa com um entendimento profundo de seus desafios de negócios e metas. Mergulhamos nas especificidades do seu setor para oferecer estratégias digitais que não apenas resolvam problemas, mas também criem oportunidades de crescimento e inovação.',
        content3: 'Não somos apenas prestadores de serviços; somos seus parceiros no caminho para a transformação digital. Juntos, podemos criar soluções que não apenas atendam às expectativas, mas que as superem, impulsionando seu negócio para novas alturas.',
        projectfeature: {
            title: 'Consultoria Web',
            lists: [
                'Atendimento Digital e Personalizado',
                'Análise Profunda dos Desafios de Negócios',
                'Estratégias Digitais Específicas por Setor',
                'Resolução de Problemas',
                'Inovações Estratégicas Digitais',
                'Tecnologias Mais Atuais de Mercado',
                'Desenvolvimento de Software sob Medida',
                'Experiência em Diversos Ramos de Atuação'
            ]
        },
        solutions: {
            title: 'Desenvolvimento Web & Mobile',
            content: 'Construímos softwares para a web ou dispositivos mobile, utilizando as tecnologias mais avançadas. Nosso foco está em criar experiências de usuário excepcionais que sejam intuitivas, atraentes e eficazes. De sites corporativos a aplicativos mobile complexos, nossa equipe assegura que cada solução seja segura, escalável e totalmente personalizada.',
            lists: [
                'Soluções Personalizadas',
                'Transformação Digital',
            ]
        },
        bestresult: {
            title: 'Melhores Resultados',
            img: DERRICOBestResults
        },
        relatedproject: {
            title: 'Related Projects.',
            lists: [
                {
                    img: img15,
                    title: 'Business Management',
                    path: '/portfolio-details',
                    cat: 'Business'
                },
                {
                    img: img15,
                    title: 'Digital Analysis',
                    path: '/portfolio-details',
                    cat: 'Business'
                },
                {
                    img: img15,
                    title: 'Fund Management',
                    path: '/portfolio-details',
                    cat: 'Finance'
                }
            ]
        }
    },
    portfoliodata: {
        img: img14,
        client: 'TechyDevs Ltd.',
        date: 'February 20, 2020',
        services: [
            {
                title: 'Business',
                path: '/'
            },
            {
                title: 'Consulting',
                path: '/'
            }
        ],
        website: 'www.techydevs.com',
        title: 'Business Management.',
        content1: 'Dut perspiciatis unde omnis iste natus error sit voluptatems accusantium doloremqu laudantiums ut, totams se aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae duis autems vell eums iriure dolors in hendrerit saep. HUGE bump in visitor-to-leads conversion. Just moving our same opt-in offer from the sidebar to the header I went from 2 percent of visitors becoming subscribers to 5 percent.',
        content2: 'Eveniet in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at seds eros sed et accumsan et iusto odio dignissim. Temporibus autem quibusdam et aut officiis. Optimizing this above-the-fold area to make the most of the screen real estate had the biggest impact of all the things I tested. Switching my opt-in box from the sidebar on the right to the header area just below my logo and navigation was a',
        content3: 'Eveniet in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at seds eros sed et accumsan et iusto odio dignissim. Temporibus autem quibusdam et aut officiis. Optimizing this above-the-fold area to make the most of the screen real estate had the biggest impact of all the things I tested. Switching my opt-in box from the sidebar on the right to the header area just below my logo and navigation was a',
        projectfeature: {
            title: 'Project Features',
            lists: [
                'Strategy and Optimisation consulting',
                'Technologies And Innovations consultant',
                'Financial Planning consultant',
                'Marketing business plan',
                'Advice business plan',
                'Strategy business plan',
                'Analize business plant',
                'Consulting business plan'
            ]
        },
        solutions: {
            title: 'Solutions',
            content: 'Adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            lists: [
                'Strategy And Optimization consulting',
                'Technologies And Innovations consultant',
                'Financial Planning consultant',
                'Secured Transactions consultant'
            ]
        },
        bestresult: {
            title: 'Better Results',
            img: chartimg
        },
        relatedproject: {
            title: 'Related Projects.',
            lists: [
                {
                    img: img15,
                    title: 'Business Management',
                    path: '/portfolio-details',
                    cat: 'Business'
                },
                {
                    img: img15,
                    title: 'Digital Analysis',
                    path: '/portfolio-details',
                    cat: 'Business'
                },
                {
                    img: img15,
                    title: 'Fund Management',
                    path: '/portfolio-details',
                    cat: 'Finance'
                }
            ]
        }
    },
    Tabdata: {
        tab1: {
            secTitle: 'Porque escolher a DERRICO Consultoria',
            secSubtitle: 'Nossos Melhores Recursos',
            tabsnav: [
                {
                    title: "Experiência Comprovada"
                },
                {
                    title: "Atendemos Online"
                },
                {
                    title: "Inovação em Tecnologias de Ponta"
                },
                {
                    title: "Soluções sob Medida"
                }
            ],
            items: [
                {
                    img: DERRICOProofExpertise,
                    desc: "Com mais de trinta anos de experiência acumulada em tecnologia, nossa empresa, embora jovem com apenas três anos de fundação, se destaca no cenário tecnológico. A profunda expertise do nosso time em uma ampla gama de projetos de Tecnologia da Informação nos permite oferecer soluções robustas e eficazes. Essas soluções são meticulosamente ajustadas para corresponder às dinâmicas do mercado e satisfazer as demandas específicas de cada cliente, assegurando assim resultados excepcionais e adequação perfeita às necessidades de nossos parceiros. ",
                    // button: "Get Started",
                    // buttonurl: '#',
                },
                {
                    img: DERRICOOnlineAttending,
                    desc: "Oferecemos uma gama completa de serviços online, projetados para garantir conveniência e eficiência máxima. Utilizando tecnologias e plataformas avançadas, nosso atendimento online é especialmente configurado para resolver questões complexas com a mesma eficácia que se esperaria de um suporte presencial. Este nível de competência online assegura a satisfação completa e o sucesso contínuo dos nossos clientes. Nossa abordagem inovadora ao suporte online nos permite lidar efetivamente com uma ampla variedade de desafios, garantindo que todas as necessidades dos clientes sejam atendidas com rapidez e precisão.",
                    // button: "Get Started",
                    // img_position: "img_right",
                    // buttonurl: '#',
                },
                {
                    img: DERRICOInovation,
                    desc: "Estamos constantemente na vanguarda do mercado, integrando as mais recentes inovações tecnológicas aos nossos serviços. Essa postura proativa nos permite oferecer soluções de ponta em software e hardware, maximizando a performance e a segurança dos projetos de nossos clientes. Nossa dedicação em manter-nos atualizados com o progresso tecnológico assegura que estamos sempre preparados para enfrentar novos desafios e aproveitar oportunidades emergentes. Ao adotar as inovações mais recentes, reforçamos nosso compromisso em promover a transformação digital eficaz e segura para todos os nossos clientes, posicionando-os para sucesso a longo prazo no ambiente competitivo atual.",
                    // button: "Get Started",
                    // buttonurl: '#',
                },
                {
                    img: DERRICOCustomizedSolutions,
                    desc: "Cada cliente é único e suas necessidades são específicas. Por isso, dedicamo-nos a oferecer atendimento personalizado, desenvolvendo soluções que se ajustam perfeitamente aos objetivos e requisitos de cada projeto. Nosso compromisso é com a entrega de resultados que não apenas atendam, mas superem as expectativas. Através de uma abordagem colaborativa e atenta aos detalhes, garantimos que cada estratégia seja implementada com precisão para maximizar o sucesso. Esta atenção ao cliente nos permite criar parcerias duradouras, fundamentadas na confiança e na eficácia contínua.",
                    // button: "Get Started",
                    // buttonurl: '#',
                }
            ]
        },
        tab2: {
            secTitle: 'Why People Choose Us.',
            secSubtitle: 'Our Best Features.',
            tabnav: [
                {
                    title: 'Wide Range Of Business Services',
                    content: 'The Argument In Favorite Of Using Filter To Text Goes Some Thing Like This Top'
                },
                {
                    title: 'Our Best Features For You',
                    content: 'The Argument In Favorite Of Using Filter To Text Goes Some Thing Like This Top'
                },
                {
                    title: 'Skills To Fulfill Your Jobs',
                    content: 'The Argument In Favorite Of Using Filter To Text Goes Some Thing Like This Top'
                },
                {
                    title: 'Our Expert Team',
                    content: 'The Argument In Favorite Of Using Filter To Text Goes Some Thing Like This Top'
                }
            ],
            tablists: [
                {
                    title: 'We Can Give the Best Facilities for Business',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad dolore eius harum incidunt iste iusto odit quibusdam quis reiciendis vero.',
                    lists: [
                        {
                            icon: <FiLock />,
                            title: 'Top Security',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        },
                        {
                            icon: <FiBarChart />,
                            title: 'Revenue Growth',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        },
                        {
                            icon: <FiUserCheck />,
                            title: 'Risk Manage',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        },
                        {
                            icon: <GiCutDiamond />,
                            title: 'Fast Support',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        }
                    ]
                },
                {
                    title: 'We Can Give the Best Facilities for Business',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad dolore eius harum incidunt iste iusto odit quibusdam quis reiciendis vero.',
                    lists: [
                        {
                            icon: <FiBarChart />,
                            title: 'Marketing Strategy',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        },
                        {
                            icon: <IoIosHammer />,
                            title: 'Best Solutions',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        },
                        {
                            icon: <AiOutlineRocket />,
                            title: 'Speed Optimization',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        },
                        {
                            icon: <GiCutDiamond />,
                            title: 'Quick Support',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        }
                    ]
                },
                {
                    title: 'We Can Give the Best Facilities for Business',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad dolore eius harum incidunt iste iusto odit quibusdam quis reiciendis vero.',
                    lists: [
                        {
                            icon: <FiLock />,
                            title: 'Top Security',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        },
                        {
                            icon: <FiBarChart />,
                            title: 'Revenue Growth',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        },
                        {
                            icon: <FiUserCheck />,
                            title: 'Risk Manage',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        },
                        {
                            icon: <GiCutDiamond />,
                            title: 'Fast Support',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        }
                    ]
                },
                {
                    title: 'We Can Give the Best Facilities for Business',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad dolore eius harum incidunt iste iusto odit quibusdam quis reiciendis vero.',
                    lists: [
                        {
                            icon: <FiBarChart />,
                            title: 'Marketing Strategy',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        },
                        {
                            icon: <IoIosHammer />,
                            title: 'Best Solutions',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        },
                        {
                            icon: <AiOutlineRocket />,
                            title: 'Speed Optimization',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        },
                        {
                            icon: <GiCutDiamond />,
                            title: 'Quick Support',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        }
                    ]
                }
            ]
        },
        tab3: {
            tabnav: [
                'Audit',
                'Strategy',
                'Sustainability'
            ],
            tablists: [
                {
                    content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don\'t look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn\'t anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet'
                },
                {
                    content: 'All the Lorem Ipsum generators on the Internet. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don\'t look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn\'t anything embarrassing hidden in the middle of text.'
                },
                {
                    content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don\'t look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn\'t anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet'
                }
            ]
        }
    },
    CounterUp: {
        secTitle: 'Numbers Speak.',
        secSubtitle: 'See What Our',
        items: [
            {
                icon: <AiOutlineNotification className="icon" />,
                number: <CountUp start={0}
                                 end={1300}
                                 duration={8}
                                 separator=" "
                                 decimal=","
                                 prefix=" "
                                 suffix=" "
                />,
                numsuffix: '+',
                title: 'Finishing Projects'
            },
            {
                icon: <AiOutlineUsergroupAdd className="icon" />,
                number: <CountUp start={0}
                                 end={195}
                                 duration={8}
                                 separator=" "
                                 decimal=","
                                 prefix=" "
                                 suffix=" "
                />,
                numsuffix: '+',
                title: 'Team Members'
            },
            {
                icon: <FaHandshake className="icon" />,
                number: <CountUp start={0}
                                 end={750}
                                 duration={8}
                                 separator=" "
                                 decimal=","
                                 prefix=" "
                                 suffix=" "
                />,
                numsuffix: '+',
                title: 'Lovely Clients'
            },
            {
                icon: <AiOutlineCheckCircle className="icon" />,
                number: <CountUp start={0}
                                 end={30}
                                 duration={8}
                                 separator=" "
                                 decimal=","
                                 prefix=" "
                                 suffix=" "
                />,
                numsuffix: '+',
                title: 'Years Of Experience'
            }
        ],
        counterbottomtitle: 'Do not hesitate to contact us for instant help and support',
        counterbottombtn: 'Let\'s Start Now',
        counterbottombtnurl: '/',
    },
    shopdata: {
        category_collection: {
            secTitle: 'Check Out Our Newest items.',
            secSubtitle: 'Newest Collection For You',
            left: {
                title: 'Product Collection',
                button: 'Shop Now',
                img: category1,
                cardlink: '/shop'
            },
            right: {
                title: 'Basket Collection',
                button: 'Shop Now',
                img: category1,
                cardlink: '/shop'
            }
        },
        sidebar: {
            categories: {
                title: 'Categories',
                lists: [
                    {
                        title: 'Accents',
                        number: '(11)',
                        path: '/product-details'
                    },
                    {
                        title: 'Accessories',
                        number: '(22)',
                        path: '/product-details'
                    },
                    {
                        title: 'Bed & Bath',
                        number: '(23)',
                        path: '/product-details'
                    },
                    {
                        title: 'Chair',
                        number: '(7)',
                        path: '/product-details'
                    },
                    {
                        title: 'Clocks',
                        number: '(3)',
                        path: '/product-details'
                    },
                    {
                        title: 'Dining',
                        number: '(11)',
                        path: '/product-details'
                    },
                    {
                        title: 'Furniture',
                        number: '(5)',
                        path: '/product-details'
                    },
                    {
                        title: 'Gifts',
                        number: '(31)',
                        path: '/product-details'
                    },
                    {
                        title: 'Lighting',
                        number: '(22)',
                        path: '/product-details'
                    },
                    {
                        title: 'Smart TVs',
                        number: '(13)',
                        path: '/product-details'
                    },
                    {
                        title: 'Table',
                        number: '(3)',
                        path: '/product-details'
                    },
                ]
            },
            productcolor: {
                title: 'Product Color.',
                lists: [
                    'black',
                    'orange',
                    'blue',
                    'darkgray'
                ]
            },
            populartags: {
                title: 'Popular Tags',
                lists: [
                    {
                        title: 'Armchairs',
                        path: '/product-details'
                    },
                    {
                        title: 'Sofas',
                        path: '/product-details'
                    },
                    {
                        title: 'Wood',
                        path: '/product-details'
                    },
                    {
                        title: 'Office',
                        path: '/product-details'
                    },
                    {
                        title: 'Desks',
                        path: '/product-details'
                    },
                    {
                        title: 'Stools',
                        path: '/product-details'
                    },
                    {
                        title: 'Footstools',
                        path: '/product-details'
                    },
                    {
                        title: 'Outdoor',
                        path: '/product-details'
                    },
                    {
                        title: 'Bedroom',
                        path: '/product-details'
                    }
                ]
            }
        }
    },
    Testimonialdata: {
        secTitle: 'Say About Us.',
        secSubtitle: 'See What Our Customers',
        imagesshape: [
            {
                img: img3
            },
            {
                img: img3
            },
            {
                img: img3
            },
            {
                img: img3
            },
            {
                img: img3
            },
            {
                img: img3
            },
            {
                img: img3
            },
            {
                img: img3
            }
        ]
    },
    Teammemberdata: {
        secTitle: 'Expert Team Members.',
        secSubtitle: 'Meet Our Dedicated',
        teammembers: [
            {
                name: 'Tanbir Ahmed',
                designation: 'Director',
                img: img4,
                socials: [
                    {
                        icon: <FaFacebookF />,
                        url: 'https://facebook.com'
                    },
                    {
                        icon: <GoX />,
                        url: 'https://twitter.com'
                    },
                    {
                        icon: <FaLinkedinIn />,
                        url: 'https://linkedin.com'
                    },
                    {
                        icon: <FaYoutube />,
                        url: 'https://youtube.com'
                    }
                ],
                buttontext: 'Read His Story',
                buttonurl: '/team-details'
            },
            {
                name: 'Adam Smith',
                designation: 'Director',
                img: img4,
                socials: [
                    {
                        icon: <FaFacebookF />,
                        url: 'https://facebook.com'
                    },
                    {
                        icon: <GoX />,
                        url: 'https://twitter.com'
                    },
                    {
                        icon: <FaLinkedinIn />,
                        url: 'https://linkedin.com'
                    },
                    {
                        icon: <FaYoutube />,
                        url: 'https://youtube.com'
                    }
                ],
                buttontext: 'Read His Story',
                buttonurl: '/team-details'
            },
            {
                name: 'Pam Sharon',
                designation: 'Director',
                img: img4,
                socials: [
                    {
                        icon: <FaFacebookF />,
                        url: 'https://facebook.com'
                    },
                    {
                        icon: <GoX />,
                        url: 'https://twitter.com'
                    },
                    {
                        icon: <FaLinkedinIn />,
                        url: 'https://linkedin.com'
                    },
                    {
                        icon: <FaYoutube />,
                        url: 'https://youtube.com'
                    }
                ],
                buttontext: 'Read His Story',
                buttonurl: '/team-details'
            }
        ],
        moreTeam: [
            {
                name: 'Tanbir Ahmed',
                designation: 'Director',
                img: img4,
                socials: [
                    {
                        icon: <FaFacebookF />,
                        url: 'https://facebook.com'
                    },
                    {
                        icon: <GoX />,
                        url: 'https://twitter.com'
                    },
                    {
                        icon: <FaLinkedinIn />,
                        url: 'https://linkedin.com'
                    },
                    {
                        icon: <FaYoutube />,
                        url: 'https://youtube.com'
                    }
                ],
                buttontext: 'Read His Story',
                buttonurl: '/team-details'
            },
            {
                name: 'Adam Smith',
                designation: 'Director',
                img: img4,
                socials: [
                    {
                        icon: <FaFacebookF />,
                        url: 'https://facebook.com'
                    },
                    {
                        icon: <GoX />,
                        url: 'https://twitter.com'
                    },
                    {
                        icon: <FaLinkedinIn />,
                        url: 'https://linkedin.com'
                    },
                    {
                        icon: <FaYoutube />,
                        url: 'https://youtube.com'
                    }
                ],
                buttontext: 'Read His Story',
                buttonurl: '/team-details'
            },
            {
                name: 'Pam Sharon',
                designation: 'Director',
                img: img4,
                socials: [
                    {
                        icon: <FaFacebookF />,
                        url: 'https://facebook.com'
                    },
                    {
                        icon: <GoX />,
                        url: 'https://twitter.com'
                    },
                    {
                        icon: <FaLinkedinIn />,
                        url: 'https://linkedin.com'
                    },
                    {
                        icon: <FaYoutube />,
                        url: 'https://youtube.com'
                    }
                ],
                buttontext: 'Read His Story',
                buttonurl: '/team-details'
            },
            {
                name: 'Domien Doe',
                designation: 'Junior Designer',
                img: img4,
                socials: [
                    {
                        icon: <FaFacebookF />,
                        url: 'https://facebook.com'
                    },
                    {
                        icon: <GoX />,
                        url: 'https://twitter.com'
                    },
                    {
                        icon: <FaLinkedinIn />,
                        url: 'https://linkedin.com'
                    },
                    {
                        icon: <FaYoutube />,
                        url: 'https://youtube.com'
                    }
                ],
                buttontext: 'Read His Story',
                buttonurl: '/team-details'
            },
            {
                name: 'Pam Sharon',
                designation: 'Director',
                img: img4,
                socials: [
                    {
                        icon: <FaFacebookF />,
                        url: 'https://facebook.com'
                    },
                    {
                        icon: <GoX />,
                        url: 'https://twitter.com'
                    },
                    {
                        icon: <FaLinkedinIn />,
                        url: 'https://linkedin.com'
                    },
                    {
                        icon: <FaYoutube />,
                        url: 'https://youtube.com'
                    }
                ],
                buttontext: 'Read His Story',
                buttonurl: '/team-details'
            },
            {
                name: 'Richard Pam',
                designation: 'Director',
                img: img4,
                socials: [
                    {
                        icon: <FaFacebookF />,
                        url: 'https://facebook.com'
                    },
                    {
                        icon: <GoX />,
                        url: 'https://twitter.com'
                    },
                    {
                        icon: <FaLinkedinIn />,
                        url: 'https://linkedin.com'
                    },
                    {
                        icon: <FaYoutube />,
                        url: 'https://youtube.com'
                    }
                ],
                buttontext: 'Read His Story',
                buttonurl: '/team-details'
            }
        ],
        teamDetails: {
            img: img12,
            name: 'Colling Roberts.',
            designation: 'Founder & CEO',
            address: 'Regional Leader Switzerland, Italy, Austria',
            phonenumber: '+123-134-6849',
            email: 'example@gmail.com',
            website: 'www.collinroberts.com',
            expertise: {
                title: 'Areas Of Expertise',
                lists: [
                    "Business transformation",
                    "Restructuring and turnaround",
                    "Integration",
                    "Growth strategy"
                ]
            },
            educations: {
                title: 'Education',
                lists: [
                    "MBA, Rotterdam School of Management, Erasmus University",
                    "BS, Engineering, Technical University of Denmark"
                ]
            },
            bottomContent: {
                title: 'A Little Description About Me.',
                content1: 'With over 20 years of experience in entrepreneurship, management, business planning, financial analysis, software engineering, operations, and decision analysis, Brandon has the breadth and depth of experience needed to quickly understand entrepreneurs businesses and craft the most suitable solutions.',
                content2: 'Investing atones demons trave runt lectores legere liusry quod ilegunt saepies claritas Inves tig ationes. Our management team is led by the Managing Partner Peter Mockler who successfully accomplished the European management buyout. My focus areas reorganization of marketing and customer services and restructuring. Throughout my pro fessional career, I led various global consulting projects, launched new marketing concepts and managed implementations. Throughout my pro fessional career, I led various global consulting projects, launched new marketing concepts.',
                blockquote: {
                    content: 'There are no secrets to success. It is the result of preparation, hard work, and learning from failure. lorem ipsum dolor sit amet.',
                    name: 'Amand Seyfrid,',
                    designation: 'Sales & Marketing, Alien Ltc.'
                }
            },
            sidebar: {
                needhelp: {
                    title: 'Need Any Help?',
                    content: 'Contact us at the Consulting office nearest to you or submit a business inquiry online',
                    button: 'Contact Us',
                    buttonurl: '/contact'
                },
                companybtn: 'Company Presentation',
                companybtnurl: '/', // Enter download file url here
            }
        }
    },
    faqdata: [
        {
            title: 'How do I find my Windows product key?',
            content: 'Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium.'
        },
        {
            title: 'Do you have any built-in caching?',
            content: 'Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium.'
        },
        {
            title: 'Can i add/upgrade my plan at any time?',
            content: 'Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium.'
        },
        {
            title: 'What access comes with my hosting plan?',
            content: 'Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium.'
        },
        {
            title: 'How do i change my password?',
            content: 'Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium.'
        },
        {
            title: 'What\'s included?',
            content: 'Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium.'
        },
        {
            title: 'What services do you provide?',
            content: 'Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium.'
        },
        {
            title: 'How much will it cost?',
            content: 'Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium.'
        }
    ],
    ctadata: {
        cta1: {
            title: 'Subscribe Newsletter to Get Latest Discount.',
            content: 'Be the first to hear about our latest products & offers. As a welcome gift, we’ll email you a 20% OFF discount code.'
        }
    },
    Chartareadata: {
        secTitle: 'Better Results.',
        secSubtitle: 'Our Growth',
        title1: 'Increase Sales',
        content1: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem eaque ipsa quae ab illo inventore sit amet, consectetur adipisicing elit. Sit, vitae.',
        title2: 'Real-Time Analytics',
        content2: 'Nullam sollicitudin nisl at nunc tempor pulvindolor sit amet, adipiscinar. Quisque gravida, tellus egett vehicula fel.',
        buttontext: 'Learn More',
        buttonurl: '/about'
    },
    Pricingdata: {
        secTitle: 'Choose Your Plan.',
        secSubtitle: 'Affordable Packages',
        tablist: [
            "Monthly",
            "Yearly"
        ],
        planlists: [
            {
                plans: [
                    {
                        title: 'Basic',
                        price: '19',
                        currency: '$',
                        mo: '/Monthly',
                        features: [
                            'Limitless concepts',
                            'Community access',
                            'Expert Reviews',
                            'Creative Layouts',
                            'Free Support',
                            'Free Fonts'
                        ],
                        buttontext: 'Get Started',
                        buttonurl: '/',
                        isactive: false
                    },
                    {
                        title: 'Popular',
                        price: '39',
                        currency: '$',
                        mo: '/Monthly',
                        features: [
                            'Limitless concepts',
                            'Community access',
                            'Expert Reviews',
                            'Creative Layouts',
                            'Free Support',
                            'Free Fonts'
                        ],
                        buttontext: 'Get Started',
                        buttonurl: '/',
                        isactive: true
                    },
                    {
                        title: 'Premium',
                        price: '78',
                        currency: '$',
                        mo: '/Monthly',
                        features: [
                            'Limitless concepts',
                            'Community access',
                            'Expert Reviews',
                            'Creative Layouts',
                            'Free Support',
                            'Free Fonts'
                        ],
                        buttontext: 'Get Started',
                        buttonurl: '/',
                        isactive: false
                    }
                ]
            },
            {
                plans: [
                    {
                        title: 'Basic',
                        price: '200',
                        currency: '$',
                        mo: '/Year(save 20%)',
                        features: [
                            'Limitless concepts',
                            'Community access',
                            'Expert Reviews',
                            'Creative Layouts',
                            'Free Support',
                            'Free Fonts'
                        ],
                        buttontext: 'Get Started',
                        buttonurl: '/',
                        isactive: false
                    },
                    {
                        title: 'Popular',
                        price: '460',
                        currency: '$',
                        mo: '/Year(save 20%)',
                        features: [
                            'Limitless concepts',
                            'Community access',
                            'Expert Reviews',
                            'Creative Layouts',
                            'Free Support',
                            'Free Fonts'
                        ],
                        buttontext: 'Get Started',
                        buttonurl: '/',
                        isactive: true
                    },
                    {
                        title: 'Premium',
                        price: '300',
                        currency: '$',
                        mo: '/Year(save 20%)',
                        features: [
                            'Limitless concepts',
                            'Community access',
                            'Expert Reviews',
                            'Creative Layouts',
                            'Free Support',
                            'Free Fonts'
                        ],
                        buttontext: 'Get Started',
                        buttonurl: '/',
                        isactive: false
                    }
                ]
            }
        ]
    },
    errorpagedata: {
        img: errorimg,
        title: 'Opps! Página não encontrada.',
        content: 'A página que você está procurando pode ter sido removida, teve seu nome alterado ou está temporariamente indisponível.',
        connector: 'ou',
        backbtn: 'Voltar para Home',
        footermenu: [
            {
                title: 'Home',
                path: '/'
            },
            {
                title: 'About Us',
                path: '/about'
            },
            {
                title: 'Service',
                path: '/services'
            },
            {
                title: 'Contact',
                path: '/contact'
            },
        ]
    },
    contactdata: {
        secTitle: 'Você tem alguma pergunta? Escreva uma mensagem.',
        secSubtitle: 'Entre em contato conosco',
        content: 'Não deixe suas dúvidas te impedirem de avançar! Entre em contato conosco hoje mesmo e descubra como nossas soluções em desenvolvimento web e mobile podem transformar seu negócio. Estamos prontos para ouvir você e ajudar a alcançar seus objetivos com tecnologia de ponta e atendimento personalizado.',
        socials: [
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/company/derrico/'
            },
            {
                icon: <FaInstagram />,
                url: 'https://www.instagram.com/derrico.consultoria'
            },
            {
                icon: <FaFacebookF />,
                url: 'https://facebook.com/derriconsultoria'
            },
            {
                icon: <GoX />,
                url: 'https://twitter.com/DERRICOnsulting'
            },
            {
                icon: <FaDiscord />,
                url: 'https://discord.gg/2ydrKMPW'
            },
            // {
            //     icon: <FaGooglePlusG />,
            //     url: 'https://plus.google.com'
            // }
        ],
        sidebar: {
            title: 'Nosso Escritório',
            img: edificioVarig,
            content: 'DERRICO Consultoria, sua parceira no desenvolvimento de software web, mobile e consultoria em TI.',
            address: 'SCN Quadra 4, Bloco B, S/N, Sala 702, Parte 336, Asa Norte, Brasília/DF, Brasil, CEP 70.714-020',
            phonenum: '+55 61 3053-5484',
            // phonenum2: '+55 61 98185-9230',
            email: 'contato@derrico.tec.br',
            // email2: 'inquiry@minzel.com',
            // button: 'Contatar'
        }
    },
    Footerdata: {
        ftLeftwidget: {
            footerlogo: DERRICOLogoBranco,
            content: 'DERRICO Consultoria, sua parceira no desenvolvimento de software web, mobile e consultoria em TI.',
            phonenumber: '+55 61 3053-5484',
            email: 'contato@derrico.tec.br',
            address: 'Brasília DF, Brasil',
            socials: {
                title: 'Siga & Conecte-se.',
                lists: [
                    {
                        icon: <FaLinkedinIn />,
                        url: 'https://www.linkedin.com/company/derrico/'
                    },
                    {
                        icon: <FaInstagram />,
                        url: 'https://www.instagram.com/derrico.consultoria'
                    },
                    {
                        icon: <FaFacebookF />,
                        url: 'https://facebook.com/derriconsultoria'
                    },
                    {
                        icon: <GoX />,
                        url: 'https://twitter.com/DERRICOnsulting'
                    },
                    {
                        icon: <FaDiscord />,
                        url: 'https://discord.gg/2ydrKMPW'
                    },
                    // {
                    //     icon: <FaYoutube />,
                    //     url: 'https://youtube.com'
                    // }
                ]
            }
        },
        ftCommonLinks: {
            title: 'Precisa de Ajuda?',
            lists: [
                // {
                //     title: 'DERRICO\'s Blog',
                //     url: '/blog-full-width'
                // },
                {
                    title: 'Sobre Nós',
                    url: '/about'
                },
                {
                    title: 'Tecnologias',
                    url: '/services'
                },
                // {
                //     title: 'Produtos',
                //     url: '/service-details'
                // },
                // {
                //     title: 'Oportunidades',
                //     url: '/'
                // },
                {
                    title: 'Contato',
                    url: '/contact'
                },
                {
                    title: 'Política da Empresa',
                    url: '/company-policy'
                },
                {
                    title: 'Política de Privacidade',
                    url: '/privacy-policy'
                }
            ]
        },
        ftSchedule: {
            title: 'Horário de Trabalho',
            offday: 'Sáb-Dom:',
            litss: [
                {
                    day: 'Segunda:',
                    time: '09:00-18:00'
                },
                {
                    day: 'Terça:',
                    time: '09:00-18:00'
                },
                {
                    day: 'Quarta:',
                    time: '09:00-18:00'
                },
                {
                    day: 'Quinta:',
                    time: '09:00-18:00'
                },
                {
                    day: 'Sexta:',
                    time: '09:00-18:00'
                }
            ]
        }
    },
    companyPrivacydata: [
        {
            title: 'Introdução',
            content: 'Bem-vindo à Política da Empresa DERRICO Consultoria em Tecnologia da Informação, sua parceira confiável em consultoria em TI e desenvolvimento web e mobile. Nosso objetivo é garantir excelência e segurança em todos os serviços oferecidos aos nossos clientes. Estas políticas aplicam-se a todos os nossos modos de operação, tanto em interações online quanto presenciais.'
        },
        {
            title: 'Código de Conduta Profissional',
            content: 'Esperamos que nossa equipe mantenha os mais altos padrões de ética profissional, com compromisso com a integridade, confidencialidade e um ambiente de trabalho respeitoso. É vital que todos os colaboradores sigam estas diretrizes para manter a confiança de nossos clientes e parceiros.'
        },
        {
            title: 'Confidencialidade e Segurança da Informação',
            content: 'A DERRICO Consultoria em Tecnologia da Informação compromete-se a proteger as informações confidenciais de nossos clientes. Utilizamos tecnologias avançadas de segurança e práticas recomendadas para prevenir o acesso não autorizado aos dados. Todos os funcionários são obrigados a seguir nossa política de segurança rigorosa.'
        },
        {
            title: 'Atendimento ao Cliente',
            content: 'Para agendar um atendimento online ou presencial, os clientes devem contatar nossa equipe pelo +55 61 3053-5484 ou contato@derrico.tec.br. Nos comprometemos a responder todas as solicitações dentro de 24 horas, garantindo eficiência e qualidade no suporte.'
        },
        {
            title: 'Política de Privacidade',
            content: 'Nossa política de privacidade, disponível em https://derrico.tec.br/privacy-policy, detalha como coletamos e usamos as informações dos clientes, em conformidade com as leis de proteção de dados aplicáveis.'
        },
        {
            title: 'Conformidade com Normas e Legislações',
            content: 'A DERRICO Consultoria em Tecnologia da Informação segue rigorosamente as leis e regulamentos do setor de TI. Nossa equipe é treinada regularmente para garantir conformidade e atualização constante.'
        },
        {
            title: 'Desenvolvimento e Entrega de Projetos',
            content: 'Nosso processo de desenvolvimento inclui várias fases, garantindo que todos os projetos sejam entregues com a mais alta qualidade. Os clientes recebem atualizações regulares durante cada fase do projeto.'
        },
        {
            title: 'Feedback e Reclamações',
            content: 'Encorajamos o feedback para melhorar continuamente nossos serviços. Reclamações podem ser enviadas diretamente para nosso canal de suporte ao cliente em administrativo@derrico.tec.br, onde serão prontamente investigadas e resolvidas.'
        },
        {
            title: 'Modificações nas Políticas',
            content: 'A DERRICO Consultoria em Tecnologia da Informação reserva-se o direito de alterar estas políticas quando necessário. Todas as alterações serão comunicadas através de nosso site oficial e e-mails diretos aos clientes.'
        },
        {
            title: 'Contato para Dúvidas',
            content: 'Para mais informações ou dúvidas sobre nossas políticas, entre em contato conosco via: Telefone: +55 61 3053-5484, e-mail: contato@derrico.tec.br ou endereço para correspondência: SCN Quadra 4, Bloco B, S/N, Sala 702, Parte 336, Asa Norte, Brasília/DF, Brasil, CEP 70.714-020.'
        }
    ],
    privacypolicydata: [
        {
            title: 'Última Atualização',
            content: '02 de maio de 2024.'
        },
        {
            title: 'Introdução',
            content: 'A Empresa DERRICO Consultoria em Tecnologia da Informação respeita a privacidade de seus clientes e está comprometida em proteger as informações pessoais que são compartilhadas conosco. Esta política de privacidade explica como coletamos, usamos, divulgamos, armazenamos e protegemos suas informações.'
        },
        {
            title: 'Coleta de Informações',
            content: 'Nós coletamos informações pessoais que você nos fornece voluntariamente ao se registrar em nossos serviços, ao preencher formulários em nosso site https://derrico.tec.br, ou durante consultas presenciais. As informações coletadas podem incluir, mas não se limitam a, seu nome, endereço de e-mail, número de telefone e detalhes do projeto.'
        },
        {
            title: 'Uso de Informações',
            content: 'Utilizamos suas informações para: 1. Fornecer, operar e manter nossos serviços; 2. Melhorar, personalizar e expandir nossos serviços; 3. Entender e analisar como você usa nossos serviços; 4. Desenvolver novos produtos, serviços, características e funcionalidades; 5. Comunicar-se com você, diretamente ou através de um de nossos parceiros, incluindo para atendimento ao cliente, para fornecer atualizações e outras informações relacionadas ao serviço, e para fins de marketing e promoção; 6. Enviar-lhe e-mails.'
        },
        {
            title: 'Compartilhamento de Informações',
            content: 'Podemos compartilhar suas informações pessoais com prestadores de serviços terceirizados que nos ajudam a operar nosso site, conduzir nossos negócios, ou servir nossos usuários, desde que essas partes concordem em manter esta informação confidencial. Também podemos compartilhar suas informações quando acreditamos que a liberação é apropriada para cumprir a lei, fazer cumprir nossas políticas de site ou proteger nossos ou outros direitos, propriedade ou segurança.'
        },
        {
            title: 'Segurança da Informação',
            content: 'A segurança de suas informações pessoais é importante para nós. Implementamos medidas de segurança adequadas para proteger suas informações de acessos não autorizados, alteração, divulgação ou destruição. No entanto, nenhum método de transmissão pela internet ou método de armazenamento eletrônico é 100% seguro e não podemos garantir sua segurança absoluta.'
        },
        {
            title: 'Acesso e Controle de suas Informações',
            content: 'Você tem direito a acessar, corrigir, alterar ou solicitar a exclusão de suas informações pessoais que mantemos. Se desejar exercer algum desses direitos, entre em contato conosco através do e-mail administrativo@derrico.tec.br ou pelo telefone +55 61 3053-5484.'
        },
        {
            title: 'Mudanças na Política de Privacidade',
            content: 'Reservamo-nos o direito de alterar esta política de privacidade a qualquer momento, portanto, revise-a frequentemente. Alterações e esclarecimentos entrarão em vigor imediatamente após sua publicação em nosso site. Se fizermos alterações materiais a esta política, notificaremos você aqui que ela foi atualizada, para que você tenha conhecimento das informações que coletamos, como as usamos e sob que circunstâncias, se houver, as usamos e/ou divulgamos.'
        },
        {
            title: 'Contato',
            content: 'Se você tiver quaisquer perguntas sobre esta política de privacidade, entre em contato conosco através do e-mail administrativo@derrico.tec.br ou pelo telefone +55 61 3053-5484.'
        }
    ],
}
export default sectiondata

