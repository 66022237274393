import React, { useState } from 'react';
import SectionsTitle from '../common/SectionsTitle';
import { FiPhoneCall } from 'react-icons/fi';
import { MdMailOutline } from 'react-icons/md';

export default function CtaForm() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const url = `${process.env.REACT_APP_DERRICO_API}/crm/`;

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            console.log(result); // Handle your success state here
            alert('Message sent successfully!');
        } catch (error) {
            console.error('There was an issue with sending the form:', error);
            alert('Failed to send message.');
        }
    };

    return (
        <section className="ctaform-wrapper">
            <div className="container">
                <div className="ctaform-grid">
                    <div className="ctaform-left">
                        <SectionsTitle title="Obtenha conselhos de nossos profissionais." subtitle="Agenda sua consultoria" />
                        <div className="ctaform-contact-info-grid text-center">
                            <div className="item">
                                <FiPhoneCall className="icon" />
                                <h2 className="item-title">Linha Direta</h2>
                                <p>+55 61 3053-5484</p>
                            </div>
                            <div className="item">
                                <MdMailOutline className="icon" />
                                <h2 className="item-title">Nosso e-mail</h2>
                                <p>contato@derrico.tec.br</p>
                            </div>
                        </div>
                    </div>
                    <div className="ctaform-right">
                        <form onSubmit={handleSubmit}>
                            <div className="form-grid">
                                <input type="text" name="name" placeholder="Nome" value={formData.name} onChange={handleChange} />
                                <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} />
                            </div>
                            <input type="text" name="subject" placeholder="Assunto" value={formData.subject} onChange={handleChange} />
                            <textarea name="message" id="textarea" placeholder="Mensagem" value={formData.message} onChange={handleChange}></textarea>
                            <button type="submit" className="theme-button">Nos envie sua mensagem</button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}
