import React  from 'react'
import Navbar from "../components/common/Navbar";
import SectionsTitle from '../components/common/SectionsTitle'
import About1 from '../components/about/About1'
import Divider from '../components/other/Divider'
import About from '../components/about/About'
import CounterUp from "../sections/CounterUp";
import HeroSlider from '../components/sliders/HeroSlider'
import SimpleTab from '../components/tabs/SimpleTab'
import Cta from "../sections/Cta";
import Team from '../components/team/Team';
import ChartArea from "../sections/ChartArea";
import Clients from "../sections/Clients";
import Pricing from "../sections/Pricing";
import CtaForm from '../components/other/CtaForm';
import Blog from '../components/blog/Blog';
import { Link } from 'react-router-dom'
import { FiChevronRight } from "react-icons/fi";
import Footer from '../components/other/Footer';
import sectiondata from "../store/store";
import ServiceSection1 from "../sections/services/ServiceSection1";
import TestimonialSection from "../sections/TestimonialSections";
import {FaWhatsapp} from "react-icons/all";
import {FaWindowClose} from "react-icons/all";

export default function Home() {

    const navigatorLanguage = navigator.language;

    switch (navigatorLanguage) {
        case 'pt-BR':
            localStorage.setItem('language', 'pt');
            break;
        case 'en-US':
            localStorage.setItem('language', 'en');
            break;
        default:
            localStorage.setItem('language', 'en');
    }

    return (
        <>
            {/* Header */}
            <header className="header-area">
                <Navbar/>
            </header>

            {/* Hero Slider */}
            <HeroSlider slideItems={sectiondata.Sliders.heroSlider1}/>

            {/*<Divider />*/}

            {/* About 1*/}
            <About1/>
            {/*<Divider />*/}

            {/* About 2 */}
            <About/>

            {/* Why Choose Us */}
            <SimpleTab secTitle={sectiondata.Tabdata.tab1.secTitle} secSubtitle={sectiondata.Tabdata.tab1.secSubtitle}
                       tabslist={sectiondata.Tabdata.tab1.items} tabsnav={sectiondata.Tabdata.tab1.tabsnav}/>

            {/* Counter Up */}
            {/*<CounterUp*/}
            {/*    secTitle={sectiondata.CounterUp.secTitle}*/}
            {/*    secSubtitle={sectiondata.CounterUp.secSubtitle}*/}
            {/*    counteritems={sectiondata.CounterUp.items}*/}
            {/*    counterbottomtitle={sectiondata.CounterUp.counterbottomtitle}*/}
            {/*    counterbottombtn={sectiondata.CounterUp.counterbottombtn}*/}
            {/*    counterbottombtnurl={sectiondata.CounterUp.counterbottombtnurl} />*/}

            {/* Testimonial*/}
            {/*<TestimonialSection*/}
            {/*    secTitle={sectiondata.Testimonialdata.secTitle}*/}
            {/*    secSubtitle={sectiondata.Testimonialdata.secSubtitle}*/}
            {/*    sliders={sectiondata.Sliders.testimonial}*/}
            {/*    imgshapes={sectiondata.Testimonialdata.imagesshape} />*/}

            {/* CTA */}
            {/*<Cta />*/}

            {/* Team */}
            {/*<Team*/}
            {/*    secTitle={sectiondata.Teammemberdata.secTitle}*/}
            {/*    secSubtitle={sectiondata.Teammemberdata.secSubtitle}*/}
            {/*    teammembers={sectiondata.Teammemberdata.teammembers} />*/}
            {/*<Divider />*/}

            {/* ChartArea */}
            {/*<ChartArea*/}
            {/*    secTitle={sectiondata.Chartareadata.secTitle}*/}
            {/*    secSubtitle={sectiondata.Chartareadata.secSubtitle}*/}
            {/*    title1={sectiondata.Chartareadata.title1}*/}
            {/*    title2={sectiondata.Chartareadata.title2}*/}
            {/*    content1={sectiondata.Chartareadata.content1}*/}
            {/*    content2={sectiondata.Chartareadata.content2}*/}
            {/*    buttontext={sectiondata.Chartareadata.buttontext}*/}
            {/*    buttonurl={sectiondata.Chartareadata.buttonurl} />*/}

            {/* Clients Logo */}
            {/*<Clients sliders={sectiondata.Sliders.clientlogos} />*/}

            {/* Pricing Table */}
            {/*<Pricing*/}
            {/*    secTitle={sectiondata.Pricingdata.secTitle}*/}
            {/*    secSubtitle={sectiondata.Pricingdata.secSubtitle}*/}
            {/*    navlist={sectiondata.Pricingdata.tablist}*/}
            {/*    planlists={sectiondata.Pricingdata.planlists} />*/}

            {/* Cta Form */}
            <CtaForm/>
            {/*<Divider />*/}

            {/* Latest News */}
            {/*<div className="h-blog-wrap">*/}
            {/*    <div className="container">*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-8">*/}
            {/*                <SectionsTitle title="Our Recent News." subtitle="Latest From The Blog" />*/}
            {/*            </div>*/}
            {/*            <div className="col-4 text-right">*/}
            {/*                <Link to="/blog-grid" className="theme-button">View All Posts <FiChevronRight className="icon" /></Link>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <Blog />*/}
            {/*</div>*/}

            {/* Footer */}
            <Footer/>
            <div className="whatsapp_chat_support wcs_fixed_left" id="example">
                <div className="wcs_button wcs_button_circle">
                    <FaWhatsapp size='50px'/>
                </div>
                <div className="wcs_button_label">
                    Atendemos via WhatsApp!
                </div>
                <div className="wcs_popup">
                    <div className="wcs_popup_close">
                        <FaWindowClose/>
                    </div>
                    <div className="wcs_popup_header">
                        <span>Precisa de Ajuda? Converse conosco!</span>
                    </div>
                    <div className="wcs_popup_person_container">
                        <div
                            className="wcs_popup_person"
                            data-number="+5561999383149"
                            data-availability='{ "monday":"09:00-18:00", "tuesday":"09:00-18:00", "wednesday":"09:00-18:00", "thursday":"09:00-18:00", "friday":"09:00-18:00" }'
                        >
                            <div className="wcs_popup_person_img"><img src="img/DerricoWA.jpeg" alt=""/></div>
                            <div className="wcs_popup_person_content">
                                <div className="wcs_popup_person_name">Geison Derrico</div>
                                <div className="wcs_popup_person_description">Especialista em Negócios</div>
                                <div className="wcs_popup_person_status">Estou Online</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

